import { WidgetHttpApiService } from '~/modules/widget/api/WidgetHttpApiService'
import { FavoritePage } from '~/modules/widget/types/FavoritePage'

export class WidgetService {
  private http: WidgetHttpApiService

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new WidgetHttpApiService(httpInstance)
  }

  getFavoritePages() {
    return new Promise<FavoritePage[]>((resolve, reject) => {
      this.http.getFavoritesPages()
        .then(response => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  createFavoritePage(payload: Omit<FavoritePage, 'id'>) {
    return new Promise<FavoritePage>((resolve, reject) => {
      this.http.createFavoritePage(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  updateFavoritePage(payload: FavoritePage) {
    return new Promise<FavoritePage>((resolve, reject) => {
      this.http.updateFavoritePage(payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }

  deleteFavoritePage(favouritePageIds: number[]) {
    return new Promise<FavoritePage>((resolve, reject) => {
      this.http.deleteFavoritePage(favouritePageIds)
        .then(response => {
          resolve(response.data)
        })
        .catch(reject)
    })
  }
}
