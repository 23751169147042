import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { FavoritePage } from '~/modules/widget/types/FavoritePage'

export class WidgetHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4',
    estateMarket: '/estate-market',
    favoritePage: '/favourite-pages',
    delete: '/delete',
    search: '/search',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  getFavoritesPages(): Promise<HttpHandledResponse<FavoritePage[]>> {
    return this.httpInstance.post<FavoritePage[]>(
      `${this.endPoints.firstPart}${this.endPoints.estateMarket}${this.endPoints.favoritePage}${this.endPoints.search}`,
    )
  }

  createFavoritePage(payload: Omit<FavoritePage, 'id'>): Promise<HttpHandledResponse<FavoritePage>> {
    return this.httpInstance.post(
      `${this.endPoints.firstPart}${this.endPoints.estateMarket}${this.endPoints.favoritePage}`,
      { ...payload },
    )
  }

  updateFavoritePage(payload: FavoritePage): Promise<HttpHandledResponse<FavoritePage>> {
    const { id, ...rest } = payload
    return this.httpInstance.put(
      `${this.endPoints.firstPart}${this.endPoints.estateMarket}${this.endPoints.favoritePage}/${id}`,
      { ...rest },
    )
  }

  deleteFavoritePage(favouritePageIds: number[]): Promise<HttpHandledResponse<FavoritePage>> {
    return this.httpInstance.post(
      `${this.endPoints.firstPart}${this.endPoints.estateMarket}${this.endPoints.favoritePage}${this.endPoints.delete}`,
      { favouritePageIds },
    )
  }
}
