<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import _ from 'lodash'
import CustomInput from '~/ui/inputs/CustomInput.vue'
import { WidgetService } from '~/modules/widget/services/WidgetService'
import { FavoritePage } from '~/modules/widget/types/FavoritePage'
import { getPageTitle } from '~/common/helpers/getPageTitle'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()

const currentUrl = computed(() => router.currentRoute.value.name)

const routeKey = computed(() => getPageTitle(currentUrl.value, undefined, undefined, true))

const routeParams = computed(() => router.currentRoute.value.params)

const routeQuery = computed(() => router.currentRoute.value.query)

const createConfirmationModal = confirmationModal()

const { t } = useI18n()

const service = new WidgetService()

const isFetching = ref<boolean>(false)

const favoritePages = ref<FavoritePage[]>([])

const favoritePagesIds = computed(() => favoritePages.value.map(favoritePage => favoritePage.id))

const isFavoritesLinksModalOpen = ref<boolean>(false)

const editingFavoritePageId = ref<number | null>(null)

const updatedName = ref('')

const errorState = ref<boolean>(false)

const inputState = ref<boolean | null>(null)

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const openFavoritesLinksModal = () => {
  if (!props.disabled) {
    isFavoritesLinksModalOpen.value = true
  }
}

const getFavoritesLinks = () => {
  isFetching.value = true
  service.getFavoritePages()
    .then(response => {
      favoritePages.value = response.data
    })
    .finally(() => {
      isFetching.value = false
    })
}

const createFavoritePage = () => {
  const favoritePage = {
    name: window.document.title,
    pageParameters: {
      routeName: routeKey.value,
      params: routeParams.value,
      queryParameters: routeQuery.value,
    },
  }
  isFetching.value = true
  service.createFavoritePage(favoritePage)
    .then(() => {
      getFavoritesLinks()
    })
    .finally(() => {
      isFetching.value = false
    })
}

const updateFavoritePage = (page: FavoritePage) => {
  isFetching.value = true
  service.updateFavoritePage({ ...page, name: updatedName.value })
    .then(() => {
      favoritePages.value = favoritePages.value.map(curPage => {
        if (curPage.id === editingFavoritePageId.value) {
          return { ...curPage, name: updatedName.value }
        }
        return curPage
      })
    })
    .finally(() => {
      isFetching.value = false
      editingFavoritePageId.value = null
    })
}

const resetFavoritePages = () => {
  if (favoritePages.value.length === 0) {
    return
  }
  createConfirmationModal({
    title: t('favoritesLinks.confirm.title'),
  })
    .then(value => {
      if (value) {
        isFetching.value = true
        service.deleteFavoritePage(favoritePagesIds.value)
          .then(() => {
            favoritePages.value = []
            toast(t('favoritesLinks.confirm.toasts.success'), { variant: 'success' })
          })
          .catch(() => {
            toast(t('favoritesLinks.confirm.toasts.error'), { variant: 'danger' })
          })
      }
    })
    .finally(() => {
      isFetching.value = false
    })
}

const deleteFavoritePage = (id: number) => {
  isFetching.value = true
  service.deleteFavoritePage([id])
    .then(() => {
      favoritePages.value = favoritePages.value.filter(favoritePage => favoritePage.id !== id)
    })
    .finally(() => {
      isFetching.value = false
    })
}

watch(isFavoritesLinksModalOpen, () => {
  if (isFavoritesLinksModalOpen.value) {
    getFavoritesLinks()
    return
  }
  editingFavoritePageId.value = null
})

watch(updatedName, value => {
  if (!value || value.trim() === '') {
    errorState.value = true
    inputState.value = false
  } else {
    errorState.value = false
    inputState.value = null
  }
})

</script>

<template>
  <div
    class="fixed bg-primary-light text-primary hover:text-white hover:bg-primary active:bg-primary-dark active:text-white bottom-[37%] right-0 cursor-pointer rotate-180 px-[2px] py-[20px] rounded-tr-[20px] rounded-br-[20px]"
    style="writing-mode: vertical-rl;"
    :class="[{'!bg-neutral-100 !text-neutral-150 !cursor-not-allowed': disabled}, 'base-widget']"
    @click="openFavoritesLinksModal"
  >
    {{ t('favoritesLinks.label') }}
  </div>
  <modal-or-bottom-sheet
    v-model="isFavoritesLinksModalOpen"
    :modal-props="{size: 'w-[375px]', bodyClass: 'pt-0'}"
    :drawer-props="{hideHeader: false, fullHeight: true}"
  >
    <template #header>
      {{ t('favoritesLinks.label') }}
    </template>

    <div
      v-if="favoritePages.length"
      :class="{'max-h-[190px] overflow-y-auto scrollbar-thin': isMd}"
    >
      <div
        v-for="page in favoritePages"
        :key="page.id"
      >
        <div class="flex items-center">
          <nuxt-link
            v-if="!editingFavoritePageId || editingFavoritePageId !== page.id"
            class="flex-grow w-[280px] truncate font-semibold cursor-pointer hover:text-primary"
            :to="{ name: ROUTES_NAMES[page.pageParameters.routeName], params: page.pageParameters.params, query: page.pageParameters.queryParameters }"
          >
            {{ page.name }}
          </nuxt-link>
          <custom-input
            v-if="editingFavoritePageId === page.id"
            v-model="updatedName"
            :class="{'!text-danger !border-danger !hover:border-danger !focus:border-danger': errorState}"
            :disabled="isFetching"
            :state="inputState"
          />
          <custom-button-icon
            v-if="!editingFavoritePageId"
            class="!w-[25px]"
            variant="withoutBorders"
            :icon-class="['text-[16px]']"
            icon="actions/edit"
            filled
            @click="editingFavoritePageId = page.id; updatedName = page.name"
          />
          <custom-button-icon
            v-if="editingFavoritePageId === page.id"
            class="!w-[25px]"
            variant="withoutBorders"
            icon-class="text-[16px]"
            icon="common/check"
            filled
            :disabled="errorState"
            @click="updateFavoritePage(page)"
          />
          <custom-button-icon
            class="!text-danger !w-[25px]"
            icon-class="text-[20px]"
            variant="withoutBorders"
            icon="actions/close"
            :disabled="isFetching"
            @click="deleteFavoritePage(page.id)"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex gap-x-3 w-full">
        <custom-button
          :disabled="!favoritePages.length || isFetching"
          class="font-semibold"
          pill
          variant="outline"
          @click="resetFavoritePages"
        >
          {{ t('favoritesLinks.reset') }}
        </custom-button>
        <custom-button
          pill
          class="w-full flex justify-center font-semibold"
          :disabled="isFetching"
          @click="createFavoritePage"
        >
          <template #prefix>
            <nuxt-icon
              class="text-[20px]"
              name="common/circle-plus-regular"
            />
          </template>
          {{ t('favoritesLinks.add') }}
        </custom-button>
      </div>
    </template>
  </modal-or-bottom-sheet>
</template>
<style lang="scss">
.base-widget {
  z-index: 1003;
}
</style>
